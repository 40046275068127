const attributesToRetrieve = [
  'configurations',
  'sku',
  'containerID',
  'sw6Uuid',
  'prices',
  'priceRules',
  'images',
  'title',
  'channelActive',
  'brand',
  'unit',
  'flags',
  'variantImages',
  'categories',
  'attributes',
  'variantData',
  'related',
  'stock',
  'deliveryDate',
  'deliveryDays',
  'specialDelivery',
  'sellOut',
  'sellable',
  'subtitle',
  'isMeterware',
  'isMainVariant',
  'productReviews',
  'descriptionAI'
]

export default attributesToRetrieve
