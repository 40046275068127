import useAccount from 'hooks/account/useAccount'
import * as React from 'react'
import config from 'config'
import lusiniFunctionsFetch from 'utils/lusiniFunctionsFetch'
import { CreateContactResponse } from 'hooks/useNewsletter'

export type ResponseType =
  | 'already_optin'
  | 'failure'
  | 'empty_mail'
  | 'success'
  | 'success_no_mail'

export default function useNewsletterSubmit() {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [response, setResponse] = React.useState<ResponseType | null>(null)
  const account = useAccount()

  const createContactWithOptIn = async (email: string) => {
    return await lusiniFunctionsFetch<CreateContactResponse>(
      'https://functions.lusini.com/.netlify/functions/newsletter-createContactWithOptIn',
      {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          location: config.locale,
          channel: account.channel,
          subscriptionSource: 'footer'
        })
      }
    ).then((res) => {
      const status: 'success' | 'failure' | string = res.status

      account._.sendEvent({
        type: 'NEWSLETTER_REGISTER',
        email: email,
        mode: res.mode
      })

      return { status, mode: res.mode, meta: res.meta || {} }
    })
  }

  const handleSubmit = async (email: string, e) => {
    e.preventDefault()
    if (isSubmitting) return
    if (!email) {
      setResponse('empty_mail')
    } else {
      setIsSubmitting(true)
      await createContactWithOptIn(email).then((res) => {
        if (res.status === 'success') {
          if (res.mode === 'ALREADY_OPTIN') setResponse('already_optin')
          else if (res.mode === 'MAIL_TRIGGER') setResponse('success')
          else if (res.mode === 'NO_MAIL_TRIGGER')
            setResponse('success_no_mail')
        } else setResponse('failure')

        setIsSubmitting(false)
      })
    }
  }

  return {
    response,
    handleSubmit,
    isSubmitting
  }
}
