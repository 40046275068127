import { addRule } from 'redux-ruleset'
import { push } from './datalayer'
import { LOCATION_CHANGED } from 'modules/navigation/const'

addRule({
  id: 'dl/NEWSLETTER',
  target: `NEWSLETTER_REGISTER`,
  output: '#dl-event',

  consequence: (action) => {
    // WDV-3463: Prevent sending the same tracking event multiple times for the same email address.
    const registeredEmailAddressesJson = localStorage.getItem(
      'newsletterRegisterEmailAddresses'
    )
    if (registeredEmailAddressesJson) {
      // We have data for the registered email addresses in the local storage.
      // Read it.
      const registeredEmailAddresses: string[] = JSON.parse(
        registeredEmailAddressesJson
      )

      if (registeredEmailAddresses.includes(action.email)) {
        // The email address has already caused a tracking event.
        return
      } else {
        // Register the email address and continue.
        registeredEmailAddresses.push(action.email)
        localStorage.setItem(
          'newsletterRegisterEmailAddresses',
          JSON.stringify(registeredEmailAddresses)
        )
      }
    } else {
      // No data in the local storage yet. Initialize it.
      localStorage.setItem(
        'newsletterRegisterEmailAddresses',
        JSON.stringify([action.email])
      )
    }

    push({
      event: 'genericEvent',
      eventname: 'newsletter_soi',
      category: 'Engagement',
      action: 'Newsletter',
      label: 'Single Opt In',
      value: 0,
      nonInteraction: 'false'
    })

    // If no double opt in mail is configured to be sent, the DOI event is triggered immediately.
    // See WDV-3463
    if (action.mode === 'NO_MAIL_TRIGGER') {
      push({
        event: 'genericEvent',
        eventname: 'newsletter_doi',
        category: 'Engagement',
        action: 'Newsletter',
        label: 'Double Opt In',
        value: 0,
        nonInteraction: 'false'
      })
    }
  }
})

addRule({
  id: 'dl/NEWSLETTER_CONFIRMATION',
  target: LOCATION_CHANGED,
  output: '#dl-event',
  condition: (action) => {
    // WDV-3463: A tracking event should be sent when the user confirms the newsletter subscription.
    // We can detect that by checking if a specific parameter is present in the URL.
    // This parameter is configured in emarsys. The confirmation link in the newsletter email contains this parameter.
    // Yes this will be checked on every page change, which I am not happy with. But I have no better solution :(.
    const urlParams = new URLSearchParams(action.payload.search)
    return urlParams.has('newsletter_verification_success')
  },
  consequence: () => {
    push({
      event: 'genericEvent',
      eventname: 'newsletter_doi',
      category: 'Engagement',
      action: 'Newsletter',
      label: 'Double Opt In',
      value: 0,
      nonInteraction: 'false'
    })
  }
})
