import createAlgoliaHelper from '../createAlgoliaHelper'
import config from '../../config'
import * as t from './types'

type Props = {
  sw6Uuids: string[]
  maxHits?: number
  keepSorting?: boolean
  noDistinct?: boolean
}

/** @firescoutMockFn productListFetcher.bySku */
export default function bySw6Uuid(props: Props) {
  const { sw6Uuids, maxHits = 50, keepSorting, noDistinct } = props
  return async (page: number): Promise<t.Result> => {
    const helper = await createAlgoliaHelper(config.index.products, {
      hitsPerPage: maxHits,
      facets: ['active', 'sellable'],
      disjunctiveFacets: ['sw6Uuid'],
      distinct: noDistinct ? 0 : 1,
      attributesToRetrieve: [
        'sku',
        'containerID',
        'sw6Uuid',
        'prices',
        'priceRules',
        'images',
        'title',
        'channelActive',
        'brand',
        'unit',
        'flags',
        'variantImages',
        'categories',
        'attributes',
        'variantData',
        'related',
        'stock',
        'deliveryDate',
        'deliveryDays',
        'specialDelivery',
        'sellOut',
        'sellable',
        'subtitle',
        'isMeterware',
        'isMainVariant',
        'isPartnerItem',
        'configurations',
        'productReviews',
        'descriptionAI'
      ]
    })
    sw6Uuids.forEach((sw6Uuid) =>
      helper.addDisjunctiveFacetRefinement('sw6Uuid', sw6Uuid)
    )

    helper.addFacetRefinement('active', 'true')
    helper.addFacetRefinement('sellable', 'true')

    helper.setPage(page)

    const result = await helper.searchOnce({})

    //Returns channelActive default true for b2b/b2c to ensure they can't be null
    result.content.hits.forEach((item, i) => {
      result.content.hits[i] = {
        ...item,
        channelActive: {
          b2c: item.channelActive?.b2c ?? true,
          b2b: item.channelActive?.b2b ?? true
        }
      }
    })

    const hitDict: Record<string, t.Product> = {}
    for (const hit of result.content.hits) hitDict[hit.sw6Uuid] = hit

    let products = sw6Uuids.map((sw6Uuid) => hitDict[sw6Uuid]).filter(Boolean)

    if (keepSorting) {
      const productDict: Record<string, t.Product> = {}
      for (const p of products) productDict[p.sw6Uuid] = p
      products = sw6Uuids.map((sw6Uuid) => productDict[sw6Uuid]).filter(Boolean)
    }
    // TODO: Remove as soon as the backend returns null instead of 'unknown'
    products.forEach((item) => {
      if (item.brand === 'unknown') item.brand = undefined
    })
    return {
      data: products,
      nbHits: maxHits < result.content.nbHits ? maxHits : result.content.nbHits,
      finished: !(result.content.nbPages > page)
    }
  }
}
