import { addRule } from 'redux-ruleset'
import config from 'config'
import calculateDeliveryInformation, {
  Variant
} from 'utils/calculateDeliveryDate'
import { PromotionItem } from 'hooks/account/types'
import { ADDRESS_COMPLETION } from 'theme/molecules/account/MainAddressForm/event'
import { isItemChannelRestricted } from 'hooks/account/utils'

type DlEvent = any

const getMainCategoryPath = (categoryPath) => {
  const obj: Record<string, any> = {}
  const arr = categoryPath.split(' / ')
  // See https://jira.em-group.de/browse/WDV-3365
  // The first category is always named item_category since this is the requirement.
  obj.item_category = arr[0]
  for (let i = 1; i < arr.length; i++) {
    const catKey = 'item_category' + (i + 1)
    const catEntity = { [catKey]: arr[i] }
    Object.assign(obj, catEntity)
  }
  return obj
}
const getCategoryPath = (categories) =>
  categories.lvl7?.[0].split('>').join('/') ||
  categories.lvl6?.[0].split('>').join('/') ||
  categories.lvl5?.[0].split('>').join('/') ||
  categories.lvl4?.[0].split('>').join('/') ||
  categories.lvl3?.[0].split('>').join('/') ||
  categories.lvl2?.[0].split('>').join('/') ||
  categories.lvl1?.[0].split('>').join('/') ||
  categories.lvl0?.[0].split('>').join('/') ||
  ''

export const push = (evt: DlEvent) => {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(evt)
  }
}

addRule({
  id: 'dl/LOGIN',
  target: `LOGIN`,
  output: '#dl-event',
  consequence: () => {
    push({
      event: 'genericEvent',
      eventname: 'logged_in',
      category: 'user',
      action: 'Login',
      label: 'success',
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'paymentList/BONI_CHECK_VIEW',
  target: 'paymentList/BONI_CHECK_VIEW',
  output: '#dl-event',
  consequence: () => {
    push({
      event: 'genericEvent',
      eventname: 'bonitaetspruefung_check',
      category: 'checkout',
      action: 'bonitätsprüfung',
      label: 'bonitätsprüfung check',
      form_id: '',
      value: 0,
      nonInteraction: true
    })
  }
})

addRule({
  id: 'paymentList/BONI_CHECK_VIEW_WITHOUT_INVOICE',
  target: 'paymentList/BONI_CHECK_VIEW_WITHOUT_INVOICE',
  output: '#dl-event',
  consequence: () => {
    push({
      event: 'genericEvent',
      eventname: 'bonitaetspruefung_keine_rechnung',
      category: 'checkout',
      action: 'bonitätsprüfung',
      label: 'keine rechnung',
      form_id: '',
      value: 0,
      nonInteraction: true
    })
  }
})

addRule({
  id: 'dl/ADDRESS_COMPLETION',
  target: [ADDRESS_COMPLETION],
  output: '#dl-event',
  addUntil: function* (next, { context }) {
    yield next([`LOGIN`], () => {
      context.set('login', true)
    })
    return 'RECREATE_RULE'
  },
  consequence: (_, { context }) => {
    const eventElement = location.href.includes('account')
      ? 'account'
      : 'checkout'
    const isLogin = context.get('login') || null
    if (isLogin)
      push({
        event: 'genericEvent',
        eventname: 'checkout_address_completion',
        interaction: 'view',
        element: eventElement,
        category: 'Engagement',
        action: eventElement,
        label: 'Address Completion',
        value: 0,
        nonInteraction: 'false'
      })
  }
})
addRule({
  id: 'dl/LOGOUT',
  target: `LOGOUT`,
  output: '#dl-event',
  consequence: () => {
    push({
      event: 'genericEvent',
      eventname: 'logged_out',
      category: 'user',
      action: 'Logout',
      label: '',
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/REGISTER',
  target: `REGISTER`,
  output: '#dl-event',
  consequence: () => {
    push({
      event: 'genericEvent',
      eventname: 'register',
      category: 'user',
      action: 'Register',
      label: '',
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/ORDER_FAILED',
  target: 'checkout/ORDER_FAILED',
  output: '#dl-event',
  addOnce: true,
  consequence: (action) => {
    push({
      event: 'genericEvent',
      eventname: 'order_failed',
      reason: action.payload.error,
      category: 'checkout',
      action: 'order-failure',
      label: action.payload.error + ' - ' + action.meta.orderId,
      value: 0,
      nonInteraction: 'true'
    })
  }
})

addRule({
  id: 'dl/change_payment',
  target: `CHANGE_PAYMENT_METHOD`,
  output: '#dl-event',
  consequence: (action) => {
    push({
      event: 'genericEvent',
      eventname: 'zahlart',
      zahlart: action.activePayment,
      category: 'zahlart',
      action: action.activePayment,
      label: '',
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/SYNC_CART',
  target: `CART_UPDATE_ITEM`,
  output: '#dl-event',
  consequence: (action) => {
    push({
      event: 'sync_cart',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: action.cart.items.map((item, i) => ({
          item_id: item.sku,
          item_name: item.title,
          affiliation: '',
          coupon: '',
          currency: config.i18n.currency_ISO,
          discount: (item.prices.piecePseudoPriceNet > 0
            ? item.prices.piecePseudoPriceNet - item.prices.piecePriceNet
            : 0
          ).toFixed(2),
          index: item.additionalInformation?.list_positon || i,
          item_brand: item.brand,
          ...getMainCategoryPath(getCategoryPath(item.categories)),
          item_list_id: item.additionalInformation?.item_list_id || '',
          item_list_name: item.additionalInformation?.item_list_name || '',
          item_variant: '',
          location_id: '',
          price:
            item.tracking?.packPriceNet ||
            item.tracking?.piecePriceNet ||
            item.prices.piecePriceNet,
          quantity: item.quantity,
          delivery_date:
            calculateDeliveryInformation(item, item.quantity).snippet ||
            item.deliveryDays
        }))
      }
    })
  }
})

addRule({
  id: 'dl/COUPON',
  target: `PROMOTION_CODE_ADD`,
  output: '#dl-event',
  consequence: (action) => {
    const cart = action.cart
    const item: PromotionItem = action.cart.promotionItems.find(
      (items) => items.code === action.code
    ) || {
      code: action.code,
      cartItemId: '-',
      reduction: 0,
      priceDefinitionType: 'absolute',
      priceDefinitionAbsolute: 0,
      priceDefinitionPercentage: 0
    }

    push({
      ecommerce: {
        purchase: {
          actionField: {
            id: item.cartItemId,
            affiliation: '',
            revenue: item.reduction,
            tax: cart.tax,
            shipping: cart.deliveryReduction,
            coupon: item.code
          },
          products: []
        }
      }
    })
  }
})

addRule({
  id: 'dl/REMOVE_FROM_CART',
  target: `CART_REMOVE_ITEM`,
  output: '#dl-event',
  condition: (action) => Boolean(action.item),
  consequence: (action) => {
    push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: [
          {
            item_id: action.item.sku,
            item_name: action.item.title,
            affiliation: '',
            coupon: '',
            currency: config.i18n.currency_ISO,
            discount: (action.item.prices.piecePseudoPriceNet > 0
              ? action.item.prices.piecePseudoPriceNet -
                action.item.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: action.item.additionalInformation?.list_positon || 0,
            item_brand: action.item.brand,
            ...getMainCategoryPath(getCategoryPath(action.item.categories)),
            item_list_id: action.item.additionalInformation?.item_list_id || '',
            item_list_name: action.item.additionalInformation?.item_list_name,
            item_variant: '',
            location_id: '',
            price:
              action.item.tracking?.packPriceNet ||
              action.item.tracking?.piecePriceNet ||
              action.item.prices.piecePriceNet,
            quantity: action.item.quantity,
            delivery_date:
              calculateDeliveryInformation(action.item, action.item.quantity)
                .snippet || action.item.deliveryDays
          }
        ]
      }
    })
  }
})

addRule({
  id: 'dl/VIEW_CART',
  target: `cart/PAGE_VIEW`,
  output: '#dl-event',
  debounce: 100,
  concurrency: 'LAST',
  consequence: (action) => {
    push({
      event: 'view_cart',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: action.payload.cart.items.map((item, i) => {
          // https://jira.em-group.de/browse/WDV-2429
          let availability = ''
          if (isItemChannelRestricted(item, 'b2b')) availability = 'Only B2C'
          if (isItemChannelRestricted(item, 'b2c')) availability = 'Only B2B'

          return {
            item_id: item.sku,
            item_name: item.title,
            affiliation: '',
            coupon: '',
            currency: config.i18n.currency_ISO,
            discount: (item.prices.piecePseudoPriceNet > 0
              ? item.prices.piecePseudoPriceNet - item.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: item.additionalInformation?.list_positon || i,
            item_brand: item.brand,
            ...getMainCategoryPath(getCategoryPath(item.categories)),
            item_list_id: item.additionalInformation?.item_list_id || '',
            item_list_name: item.additionalInformation?.item_list_name,
            item_variant: '',
            location_id: '',
            price:
              item.tracking?.packPriceNet ||
              item.tracking?.piecePriceNet ||
              item.prices.piecePriceNet,
            quantity: item.quantity,
            availability: availability,
            delivery_date:
              calculateDeliveryInformation(item, item.quantity).snippet ||
              item.deliveryDays
          }
        })
      }
    })
  }
})
addRule({
  id: 'dl/BEGIN_CHECKOUT',
  target: `cart/BEGIN_CHECKOUT`,
  output: '#dl-event',
  consequence: (action) => {
    push({
      event: 'begin_checkout',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: action.payload.cart.items.map((item, i) => ({
          item_id: item.sku,
          item_name: item.title,
          affiliation: '',
          coupon: '',
          currency: config.i18n.currency_ISO,
          discount: (item.prices.piecePseudoPriceNet > 0
            ? item.prices.piecePseudoPriceNet - item.prices.piecePriceNet
            : 0
          ).toFixed(2),
          index: item.additionalInformation?.list_positon || i,
          item_brand: item.brand,
          ...getMainCategoryPath(getCategoryPath(item.categories)),
          item_list_id: item.additionalInformation?.item_list_id || '',
          item_list_name: item.additionalInformation?.item_list_name || '',
          item_variant: '',
          location_id: '',
          price:
            item.tracking?.packPriceNet ||
            item.tracking?.piecePriceNet ||
            item.prices.piecePriceNet,
          quantity: item.quantity,
          delivery_date:
            calculateDeliveryInformation(item, item.quantity).snippet ||
            item.deliveryDays
        }))
      }
    })
  }
})

addRule({
  id: 'dl/ADD_PAYMENT_INFO',
  target: [`checkout/PAYMENT_INFO`],
  output: '#dl-event',
  consequence: (action) => {
    const cart = action.cart

    push({
      event: 'add_payment_info',
      value: cart.totalPrice,
      payment_type: action.activePayment,
      coupon: cart.promotionItems.length > 0 ? cart.promotionItems[0].code : '',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        value: cart.totalPrice,
        payment_type: action.activePayment,
        items: cart.items.map((item, i) => ({
          item_id: item.sku,
          item_name: item.title,
          affiliation: '',
          coupon: '',
          currency: config.i18n.currency_ISO,
          discount: (item.prices.piecePseudoPriceNet > 0
            ? item.prices.piecePseudoPriceNet - item.prices.piecePriceNet
            : 0
          ).toFixed(2),
          index: item.additionalInformation?.list_positon || i,
          item_brand: item.brand,
          ...getMainCategoryPath(getCategoryPath(item.categories)),
          item_list_id: item.additionalInformation?.item_list_id || '',
          item_list_name: item.additionalInformation?.item_list_name || '',
          item_variant: '',
          location_id: '',
          price:
            item.tracking?.packPriceNet ||
            item.tracking?.piecePriceNet ||
            item.prices.piecePriceNet,
          quantity: item.quantity,
          delivery_date:
            calculateDeliveryInformation(item, item.quantity).snippet ||
            item.deliveryDays
        }))
      }
    })
  }
})
let allreadySendShippingEvent = false
addRule({
  id: 'dl/ADD_SHIPPING_INFO',
  target: [`checkout/SHIPPING_INFO`, `CHANGE_SHIPPING_METHOD`],
  output: '#dl-event',
  consequence: (action) => {
    let shippinName = ''
    let paymentName = ''

    const cart =
      action.type === 'CHANGE_SHIPPING_METHOD'
        ? action.cart
        : action.payload.cart
    if (action.type === 'checkout/SHIPPING_INFO') {
      shippinName = action.payload.shippingName
      paymentName = action.payload.paymentName
    }
    if (action.type == 'CHANGE_SHIPPING_METHOD' || !allreadySendShippingEvent) {
      allreadySendShippingEvent = true
      push({
        event: 'add_shipping_info',
        ecommerce: {
          currency: config.i18n.currency_ISO,
          value: cart.totalPrice,
          payment_type: paymentName,
          shipping_tier: shippinName,
          items: cart.items.map((item, i) => ({
            item_id: item.sku,
            item_name: item.title,
            affiliation: '',
            coupon: '',
            currency: config.i18n.currency_ISO,
            discount: (item.prices.piecePseudoPriceNet > 0
              ? item.prices.piecePseudoPriceNet - item.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: item.additionalInformation?.list_positon || i,
            item_brand: item.brand,
            ...getMainCategoryPath(getCategoryPath(item.categories)),
            item_list_id: item.additionalInformation?.item_list_id || '',
            item_list_name: item.additionalInformation?.item_list_name || '',
            item_variant: '',
            location_id: '',
            price:
              item.tracking?.packPriceNet ||
              item.tracking?.piecePriceNet ||
              item.prices.piecePriceNet,
            quantity: item.quantity,
            delivery_date:
              calculateDeliveryInformation(item, item.quantity).snippet ||
              item.deliveryDays
          }))
        }
      })
    }
  }
})

addRule({
  id: 'dl/PURCHASE',
  target: `checkout/PURCHASE`,
  output: '#dl-event',

  consequence: (action) => {
    const order = action.meta.order

    push({
      event: 'purchase',
      zahlart: action.payload.paymentType,
      b2bKunde: action.payload.b2bCustomer === 'b2b' ? 'ja' : 'nein',
      aussensdienstBestellung: action.payload.isOutsideSalesRep ? 'Ja' : 'Nein',
      ecommerce: {
        transaction_id: order.orderNumber,
        affiliation: '',
        value: order.totalPrice - order.tax,
        tax: order.tax,
        shipping: order.deliveryCost,
        currency: config.i18n.currency_ISO,
        coupon:
          order.promotionItems.length > 0 ? order.promotionItems[0].code : '',
        payment_type: action.payload.paymentType,
        shipping_tier: action.payload.shippingName,
        items: action.payload.items.map((item, i) => ({
          item_id: item.product?.sku,
          item_name: item.product?.title,
          affiliation: '',
          coupon: '',
          currency: config.i18n.currency_ISO,
          discount: ((item.product?.prices.piecePseudoPriceNet as number) > 0
            ? (item.product?.prices.piecePseudoPriceNet as number) -
              (item.product?.prices.piecePriceNet || 0)
            : 0
          ).toFixed(2),
          index: item.product?.additionalInformation?.list_positon || i,
          item_brand: item.product?.brand,
          ...getMainCategoryPath(getCategoryPath(item.product?.categories)),
          item_list_id: item.product?.additionalInformation?.item_list_id || '',
          item_list_name:
            item.product?.additionalInformation?.item_list_name || '',
          item_variant: '',
          location_id: '',
          price:
            item.tracking?.packPriceNet ||
            item.tracking?.piecePriceNet ||
            item.product?.prices.piecePriceNet,
          quantity: item.amount,
          delivery_date:
            calculateDeliveryInformation(
              { ...(item.product as Variant) },
              item.amount
            ).snippet || item.product?.deliveryDays
        }))
      }
    })
  }
})

addRule({
  id: 'dl/FLYOUTCART_CLOSE',
  target: 'FlyoutCart/CLOSE',
  output: '#dl-event',
  consequence: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'genericEvent',
      eventname: 'continue_shopping'
    })
  }
})

addRule({
  id: 'dl/CREDIT_CHECK_MODAL_OPEN',
  target: 'PaymentSummary/Modal/CREDIT_CHECK_MODAL_OPEN',
  output: '#dl-event',
  consequence: (event) => {
    push({
      event: 'genericEvent',
      eventname: 'credit_check_modal',
      action: 'open',
      cart_value: event.meta.cartValue
    })
  }
})

addRule({
  id: 'dl/CREDIT_CHECK_MODAL_SELECT',
  target: 'PaymentSummary/Modal/CREDIT_CHECK_MODAL_SELECT',
  output: '#dl-event',
  consequence: (event) => {
    push({
      event: 'genericEvent',
      eventname: 'credit_check_modal',
      action: 'select',
      selected_payment: event.meta.selectedPayment,
      cart_value: event.meta.cartValue
    })
  }
})
